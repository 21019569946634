import styled from "@emotion/styled"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { css } from "@emotion/react"
import { useTranslation, Trans } from "gatsby-plugin-react-i18next"

import Lead from "./Lead"
import { mediaQueries } from "../../styles"

const Grid = styled.div`
  display: flex;
  flex-direction: column;

  ${mediaQueries.min992} {
    display: grid;
    grid-template-columns: 14.8vw 5.2vw 21.9vw 1.3vw 14.7vw 6.4vw 7.5vw;
    grid-template-rows: 19.2vw minmax(16.3vw, auto) 5.9vw minmax(19.2vw, auto) 2.1vw 5.2vw 9.7vw minmax(
        1.7vw,
        auto
      );
  }
`

const mobileMarginBottom = css`
  ${mediaQueries.max576} {
    margin-bottom: 3rem;

    h4 {
      margin-bottom: 1rem;
    }
  }
`

const mobileH4MarginBottom = css`
  ${mediaQueries.max576} {
    h4 {
      margin-bottom: 1rem;
    }
  }
`

const Origin = styled.div`
  ${mobileMarginBottom}
  ${mobileH4MarginBottom}

  z-index: 1;
  grid-column: 1 / span 2;
  grid-row: 4 / span 1;
`

const ManihotContainer = styled.div`
  ${mobileMarginBottom}
  ${mobileH4MarginBottom}

  position: relative;
  grid-column: 2 / span 2;
  grid-row: 1 / span 4;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;

  ${mediaQueries.max576} {
    width: 100%;
    order: -1;

    .gatsby-image-wrapper {
      max-width: 240px;
    }
  }
`

const DescriptionLead = styled(Lead)`
  grid-column: 5 / span 3;
  grid-row: 2 / span 1;
  align-self: flex-end;
  max-width: 22ch;
  margin: 0;
  letter-spacing: 0.5px;

  ${mediaQueries.max576} {
    display: none;
  }
`

const Morphology = styled.div`
  ${mobileMarginBottom}
  ${mobileH4MarginBottom}

  grid-column: 5 / span 2;
  grid-row: 4 / span 2;

  p {
    max-width: 25rem;
  }
`

const Farming = styled.div`
  ${mobileMarginBottom}
  ${mobileH4MarginBottom}

  grid-column: 3 / span 1;
  grid-row: 5 / span 4;
`

const CassavaVarieties = styled.div`
  ${mobileH4MarginBottom}

  grid-column: 6 / span 2;
  grid-row: 7 / span 2;
`

const LaPlantaGrid = () => {
  const data = useStaticQuery(graphql`
    {
      manihot: file(relativePath: { eq: "manihot.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 469, layout: CONSTRAINED)
        }
      }
    }
  `)

  const { t } = useTranslation("", { keyPrefix: "laPlanta" })

  return (
    <Grid>
      <Origin>
        <h4>{t("origin.title")}</h4>
        <p>
          <Trans
            i18nKey="laPlanta.origin.content"
            components={{ bold: <strong /> }}
          />
        </p>
      </Origin>
      <ManihotContainer>
        <GatsbyImage
          image={data.manihot.childImageSharp.gatsbyImageData}
          alt="Manihot"
        />
      </ManihotContainer>
      <DescriptionLead>{t("description")}</DescriptionLead>
      <Morphology>
        <h4>{t("morphology.title")}</h4>
        <p>{t("morphology.content")}</p>
      </Morphology>
      <Farming>
        <h4>{t("farming.title")}</h4>
        <p>{t("farming.content")}</p>
      </Farming>

      <CassavaVarieties>
        <h4>{t("cassavaVarieties.title")}</h4>
        <p>{t("cassavaVarieties.content")}</p>
      </CassavaVarieties>
    </Grid>
  )
}

export default LaPlantaGrid
